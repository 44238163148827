<template>
    <div>
        <b-container fluid class="bv-example-row bv-example-row-flex-cols">
            <b-row align-h="end" class="pb-2">

                <b-col cols="10" class="text-center p-0">
                    <b-input-group size="sm" class="w-25">
                        <b-input-group-prepend is-text>
                            <b-icon icon="search"></b-icon>
                        </b-input-group-prepend>

                        <b-form-input placeholder="Buscar..."
                                      v-model="value"
                                      @input="$emit('pesquisar', value)">
                        </b-form-input>
                    </b-input-group>
                </b-col>

                <b-col cols="2" class="text-right px-0">
                    <slot></slot>

                    <botao-padrao class="header-funcoes-lista-botao-nova-empresa"
                                  :texto="textoBotao"
                                  @click="$emit('click')" />
                </b-col>

            </b-row>
        </b-container>
    </div>
</template>

<script>
    import BotaoPadrao from '@/components/botoes/BotaoPadrao.vue'

    export default {
        name: 'HeaderFuncoesLista',
        components: {
            BotaoPadrao
        },
        props: {
            textoBotao: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                value: ''
            }
        },
    }
</script>

<style scoped>
    .header-funcoes-lista-botao-nova-empresa {
        min-width: 50%;
        width: auto;
    }

    .button-hover-padrao {
        border-color: #007bff;
        color: #007bff;
    }

        .button-hover-padrao:hover {
            background-color: var(--cinza-5) !important;
            border-color: var(--cinza-5) !important;
            color: white;
        }
        .button-hover-padrao:active {
            background-color: rgb(168, 168, 168) !important;
            border-color: rgb(168, 168, 168) !important;
            color: white;
        }
</style>