<template>
    <div>
        <b-modal ref="formAtualizarLicenca"
                 hide-footer centered size="lg"
                 title="Atualizar licença">

            <b-container fluid>
                <b-row cols="3">

                    <b-col>
                        <input-padrao label="Data de Início *" tipo="date" v-model.trim="$v.inicioLicenca.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.inicioLicenca.$invalid">Campo data de início é obrigátório</p>
                    </b-col>

                    <b-col>
                        <input-padrao label="Data de Termino *" tipo="date" v-model.trim="$v.fimLicenca.$model"/>
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.fimLicenca.$invalid">Campo data de termino é obrigátório</p>
                    </b-col>

                    <b-col>
                        <input-padrao label="Quantidade de Licenças *" tipo="number" v-model.trim="$v.numeroLicencas.$model" />
                        <p class="txt-erro-validacao" v-if="clickSalvar && $v.numeroLicencas.$invalid">Campo quantidade de licenças é obrigatório</p>
                    </b-col>

                </b-row>
            </b-container>

            <div class="w-100 mt-3 text-right">
                <botao-modal-cancel class="d-inline mr-2" @click="fechar">Cancelar</botao-modal-cancel>
                <botao-modal-ok class="d-inline" @click="salvar">Salvar</botao-modal-ok>

            </div>

        </b-modal>
    </div>
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    import * as SDUtils from '@/assets/js/SDUtils'
    import InputPadrao from '@/components/inputs/InputPadrao.vue'
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue'
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue'

    export default {
        name: 'FormAtualizaLicenca',
        components: {
            InputPadrao,
            BotaoModalOk,
            BotaoModalCancel
        },
        props: {
        },
        data() {
            return {
                id: '',
                inicioLicenca: '',
                fimLicenca: '',
                numeroLicencas: 0,

                clickSalvar: false
            }
        },
        methods: {
            show() {
                this.$refs['formAtualizarLicenca'].show()
            },
            fechar() {
                this.limpar();
                this.$refs['formAtualizarLicenca'].hide();
            },
            atualizar(licenca) {
                this.id = licenca.Id;
                this.inicioLicenca = SDUtils.formatarDataCalendario(licenca.InicioLicenca);
                this.fimLicenca = SDUtils.formatarDataCalendario(licenca.FimLicenca);
                this.numeroLicencas = licenca.NumeroLicencas;
            },
            limpar() {
                this.clickSalvar = false;

                this.id = '';
                this.inicioLicenca = '';
                this.fimLicenca = '';
                this.numeroLicencas = 0;
            },
            salvar() {
                this.clickSalvar = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    return
                }

                let licenca = {
                    'Id': this.id,
                    'InicioLicenca': this.inicioLicenca,
                    'FimLicenca': this.fimLicenca,
                    'NumeroLicencas': this.numeroLicencas,
                }

                this.$store.dispatch(`empresasLicencas/atualizar`, licenca);
                this.fechar();
            },
            formatarDataCalendario(dataString) {
                let data = new Date.parse(dataString.slice(0, 10))
                return data;
            }
        },
        validations: {
            inicioLicenca: { required },
            fimLicenca: { required },
            numeroLicencas: { required },
        },
    }
</script>

<style scoped>
    label {
        font-size: 14px;
        margin: 5px 0 2px 0;
    }

    .txt-erro-validacao {
        color: red;
        font-size: 12px;
    }
</style>