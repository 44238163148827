<template>
    <div>
        <Breadcrumb titulo="Empresas Licenciadas" :items="item" backgroundColor="#aaa2bf" />

        <div class="licencas-page-container-externo-tabela">
            <div class="licencas-page-container-interno-tabela">
                <header-funcoes-lista :textoBotao="'Nova Licença'"
                                      @click="abrirFormCadastroEmpresa()"
                                      @pesquisar="pesquisarEmpresa" />

                <tabela-empresas-licenciadas class="licencas-page-tabela"
                                             :colEmpresas="colunasEmpresas"
                                             :listaEmpresas="listaEmpresasComFiltro"
                                             :colLicencas="colunasLicencas"
                                             :listaLicencas="listaLicencas"
                                             :adminSharkdataAutorizado="dadosUsuarioAutenticado.userShark"
                                             @editar-empresa="abrirFormCadastroEmpresa"
                                             @atualizar-licenca="abrirFormAtualizaLicenca" />

                <form-cadastro-empresa 
                    ref="formCadastroEmpresa"
                    :listaTiposCanais="listaTiposCanais"
                    :listaModulosHabilitados="listaEmpresaModulosHabilitados"
                />
                <form-atualiza-licenca ref="formAtualizaLicenca" />
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
import HeaderFuncoesLista from '@/components/tabelas/HeaderFuncoesLista.vue'
import TabelaEmpresasLicenciadas from '@/components/licencas/TabelaEmpresasLicenciadas.vue'
import FormCadastroEmpresa from '@/components/licencas/FormCadastroEmpresa.vue'
import FormAtualizaLicenca from '@/components/licencas/FormAtualizaLicenca.vue'


export default {
    name: 'LicencasPage',

    components: {
        Breadcrumb,
        HeaderFuncoesLista,
        TabelaEmpresasLicenciadas,
        FormCadastroEmpresa,
        FormAtualizaLicenca
    },

    data: function () {
        return {
            item: [{
                id: '2',
                classe: 'fas fa-address-card',
                texto: 'Empresas Licenciadas',
                link: '/licencas'
            }],
            colunasEmpresas: [
                { key: 'collapse', label: '' },

                { key: 'Nome', label: 'Nome', sortable: true, tdClass: 'tab-empresa-coluna' },
                { key: 'HostChat', label: 'Host Chat', sortable: true, tdClass: 'tab-empresa-coluna' },
                { key: 'NumeroLicencas', label: 'Qtde. de Licenças', sortable: true, tdClass: 'tab-empresa-coluna' },
                { key: 'NumeroMaximoLicenca', label: 'Qtde. máximo de Licenças', sortable: true, thStyle: { 'min-width': '14rem' } },
                { key: 'InicioLicenca', label: 'Data de Início', sortable: true, tdClass: 'tab-empresa-coluna' },
                { key: 'FimLicenca', label: 'Data de Termino', sortable: true, tdClass: 'tab-empresa-coluna' },
                { key: 'UsuarioAdmin', label: 'Usuário administrador', sortable: true, thStyle: { 'min-width': '13rem' } },
                { key: 'EmailAdmin', label: 'E-mail administrador', sortable: true, tdClass: 'tab-empresa-coluna' },
                { key: 'MultEmpresas', label: 'Mult', sortable: true, tdClass: 'tab-empresa-mult' },

                { key: 'botoes', label: '', stickyColumn: true },
            ],
            colunasLicencas: [
                { key: 'InicioLicenca', label: 'Início da Licença', sortable: true },
                { key: 'FimLicenca', label: 'Fim da Licença', sortable: true },
                { key: 'NumeroLicencas', label: 'Qtde. de Licenças', sortable: true },
                { key: 'NumeroMaximoLicenca', label: 'Qtde. máximo de Licenças', sortable: true },
                { key: 'Alteracao', label: 'Dt. Log', sortable: true },

                { key: 'botoes', label: '' },
            ],
            textoPesquisa: ''
        }
    },
    
    computed: {
        ...mapGetters({
            listaEmpresas: 'empresasLicencas/listaEmpresas',
            listaLicencas: 'empresasLicencas/listaLicencas',
            listaEmpresaModulosHabilitados: 'empresasLicencas/listaEmpresaModulosHabilitados',
            dadosUsuarioAutenticado: 'authentication/authDadosUsuario',
            listaTiposCanais: 'canais/listaTiposCanais',
        }),
        listaEmpresasComFiltro() {
            if (this.textoPesquisa) {
                return this.listaEmpresas.filter(prop =>
                    prop.Nome.toLowerCase().includes(this.textoPesquisa) ||
                    prop.UsuarioAdmin.toLowerCase().includes(this.textoPesquisa) ||
                    prop.EmailAdmin.toLowerCase().includes(this.textoPesquisa)
                );
            }
            return this.listaEmpresas;
        }
    },

    created() {
        this.buscaEmpresas();
        this.buscaLicencas();
        this.buscaTiposCanais();
    },

    methods: {
        ...mapActions({
            buscaEmpresas: 'empresasLicencas/buscaEmpresas',
            buscaLicencas: 'empresasLicencas/buscaLicencas',
            buscaTiposCanais: 'canais/buscaTiposCanais',
        }),
        abrirFormCadastroEmpresa(empresaId) {
            if (!this.dadosUsuarioAutenticado?.userShark) {
                alert("Acesso permitido somente à Sharkdata")
                return
            }

            this.$refs.formCadastroEmpresa.limpar();

            if (empresaId) {
                let empresaLicenciada = this.listaEmpresas.find(x => x.Id === empresaId)
                this.$refs.formCadastroEmpresa.editar(empresaLicenciada);
            }

            this.$refs.formCadastroEmpresa.show();
        },
        abrirFormAtualizaLicenca(empresaId) {
            let empresa = this.listaEmpresas.find(x => x.Id === empresaId);
            this.$refs.formAtualizaLicenca.limpar();
            this.$refs.formAtualizaLicenca.atualizar(empresa);
            this.$refs.formAtualizaLicenca.show();
        },
        pesquisarEmpresa(texto) {
            this.textoPesquisa = texto.toLowerCase();
        }
    }
}
</script>

<style scoped>
.tab-empresa-coluna {
    min-width: 10rem;
}

.licencas-page-container-externo-tabela {
    padding: 12px;
}

.licencas-page-container-interno-tabela {
    padding: 10px;
    background-color: var(--cinza-3);
    width: 100%;
    height: calc(100vh - 160px);
    overflow: hidden;
}

.licencas-page-tabela {
    background-color: #fff;
    height: calc(100vh - 160px);
}

</style>