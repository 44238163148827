// Formata data no formato dd/mm/yyyy
export const dataFormatada = (data) => {
    if(!data)
        return new Date();
    var dia  = data.getDate().toString(),
        diaF = (dia.length == 1) ? '0'+dia : dia,
        mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
        mesF = (mes.length == 1) ? '0'+mes : mes,
        anoF = data.getFullYear();
    return diaF+"/"+mesF+"/"+anoF;
}

// Formata data no formato dd/mm/yyyy - hh:MM:ss
export const dataHoraFormatada = (dataInput) => {

  if(!dataInput) {
    throw new Error('Data de entrada para formatação inválida')
  }
  
  let data = (typeof dataInput) === 'string' ? new Date(dataInput) : dataInput

  const zeroEsquerda = (num) => (num.length == 1) ? '0' + num : num

  let dia = zeroEsquerda(data.getDate().toString())
  let mes = zeroEsquerda((data.getMonth() + 1).toString())
  let ano = data.getFullYear().toString()
  let hora = zeroEsquerda(data.getHours().toString())
  let min = zeroEsquerda(data.getMinutes().toString())
  let seg = zeroEsquerda(data.getSeconds().toString())

  return `${dia}/${mes}/${ano} - ${hora}:${min}:${seg}`
}

export const formatarDataCalendario = (dataString) => {
    // trata e retorna sem horario
    dataString = dataString ?? new Date().toISOString()
    let data = dataString.slice(0, 10).replaceAll('/', '-')

    // verifica pra retornar data iniciada pelo ano (ex: '2022-12-31')
    let dataSeparadaArray = data.split('-')
    const QtdeNumerosAno = 4
    return dataSeparadaArray[0].length !== QtdeNumerosAno ?
        dataSeparadaArray.reverse().join('-')
        : data
}

export const formataJsonData = (dataString) => {
    if (dataString) {
        return dataString.slice(0, 10).replaceAll('-', '/').split('/').reverse().join('/');
    }
    return '';
}

export const formatarDataParaInput = (dataString) => { // --> '31/12/2022'
    return dataString?.split('/').reverse().join('-') // <-- '2022-12-31'
}

export const formatarMoedaReal = (valor = 0) => {
    if (typeof valor === 'number')
        return valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) // <-- ex: '2.345,90'
    else if (typeof valor === 'string')
        return parseFloat(valor?.replaceAll('.', '').replace(',', '.')) // <-- ex: 2345.90
}

export const formatarTelefone = (numeroTel) => {
    if(!numeroTel) {
        return '';
    }

    let numero = numeroTel.toString();
    let length = numero.length;
    let telefoneFormatado;

    if (length === 10) {
        telefoneFormatado = '(' + numero.substring(0, 2) + ') ' + numero.substring(2, 6) + '-' + numero.substring(6, 10);
    } else if (length === 11) {
        telefoneFormatado = '(' + numero.substring(0, 2) + ') ' + numero.substring(2, 3) + ' ' + numero.substring(3, 7) + '-' + numero.substring(7, 11);
    } else {
        telefoneFormatado = '';
    }
    return telefoneFormatado;
}

// Converte as propriedades de um objeto de camelCase para CapitalCase
// Entra
// {
//   PropA: 'string A',
//   ProB: 'string B'
// } 
// Sai
// {
//   propA: 'string A',
//   proB: 'string B'
// }
export function objToCapitalCase(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
      return o.map(function(value) {
          if (typeof value === "object") {
            value = objToCapitalCase(value)
          }
          return value
      })
    } else {
      newO = {}
      for (origKey in o) {
        if (Object.prototype.hasOwnProperty.call(o, origKey)) {
          newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString()
          value = o[origKey]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = objToCapitalCase(value)
          }
          newO[newKey] = value
        }
      }
    }
    return newO
}

export function filtrarArrayPorObjValue(array, textoPesquisa) {
    return array.filter(obj => {
        return Object.keys(obj).some(value => {
            if (!!value && typeof obj[value] === 'string') {
                return obj[value].toLowerCase().includes(textoPesquisa.toLowerCase());
            }
        });
    });
}

